@import "lib/lib.less";
@import "variables.less";

html {
    .reset-basic();
}
body {
    .transition('background .4s ease-in-out');

    &.course {
        background: #ecf0f1;

        .navbar-container, .course-navigation-buttons {
            background: #fff;
        }

        .slides-overview {
            background: #ecf0f1;
        }
    }
}

//
// TYPOGRAPHY
//

h1 {
    font-family: 'Francois One', sans-serif; 
    font-size: 5rem;
    font-weight: bold;
    color: @color-secondary;
    text-transform: uppercase;
}

h2 {
    font-family: 'Francois One', sans-serif; 
    font-size: 3.5rem;
    font-weight: bold;
    color: @color-primary;
}

h3 {
    margin: 0 0;
    font-size: 2rem;
    text-transform: uppercase;
}

h3, h4, h5, h6 {
    font-family: 'Francois One', sans-serif; 
    font-weight: bold;
    color: @color-primary;
}

p {
    font-size: 1.8rem;
}

ul {
    .reset-basic();
    padding-left: 30px;
    li {
        position: relative;
        font-size: 1.8rem;
        &:before {
            content: '';
            position: absolute;
            top: 8px;
            left: -25px;
            width: 10px;
            height: 10px;
            background: @color-secondary;
        }
    }
}

//
// HEADER
//

header {
    width: 992px;
    margin: 0 auto;
}

@media (min-width: 1300px) {
    header {
        width: 1280px;
    }
}

@media (min-width: 1920px) {
    header {
        width: 1440px;
    }
}

//
// MAIN CONTENT
//

.content {
    width: 992px;
    margin: 0 auto;
    padding: 0 0 0 30px;
    min-height: 435px;
    box-sizing: border-box;
    background: url('../images/bg-landscape.png') no-repeat center center;
    background-size: auto 100%;

    &-clear {
        background: none;
    }
}

.reactor {
    width: 100%;
    height: 330px;
    margin-bottom: 33px;
    background: url('../images/bg-building.png') no-repeat center center;
    background-size: auto 100%;

    &-expanded {
        height: 440px;
        background: url('../images/bg-building-numbers.png') center center no-repeat;
        background-size: contain;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        margin: 0;
    }
}

@media (min-width: 1300px) {
    .content {
        width: 1280px;
    }

    .reactor {
        height: 340px;
        margin-bottom: 36px;

        &-expanded {
            height: 400px;
            top: 30px;
            margin: 0;
        }
    }
}

@media (min-width: 1920px) {
    .content {
        width: 1440px;
    }

    .reactor {
        height: 450px;
        margin-bottom: 92px;

        &-expanded {
            height: 440px;
            margin: 0;
        }
    }
}

//
// PAGES
//

.page {
    text-align: left;
    padding: 0 0 20px;

    &-title {
        text-align: center;
        margin: 20px 0 0;
    }

    &-content {
        padding: 30px 0 30px;

        &-movie {
            video {
                width: auto;
                height: 100%;
                margin: 0 auto;
                display: block; 
            }
        }
    }

    &-astec-modules {
        padding: 0;
        background: none;
        //background: url('../images/bg-building-lines.png') center center no-repeat;
        //background-size: contain;
        position: relative;
    }
}

@media (min-width: 1300px) {
    .page {
        &-astec-modules {
            background-size: contain;
        }
    }
}

.astec-modules {
    .reset-basic(); 
    position: relative;
    display: block;
    width: 100%;
    height: 508px;

    &-module {
        position: absolute;
        top: 0;
        left: 0;
        width: 250px;

        &:before {
            display: none;
        }
    }

    &-submodule {
        width: 100%;
        height: auto;
        text-align: center;
        vertical-align: middle;
        line-height: 40px;
        padding: 2px 0 2px;
        margin: 0 0 5px;

        &-border {
            border-bottom: 1px solid @color-secondary;
        }
    }
}

.astec-module {

    &-header {
        font-size: 2rem;
        margin-bottom: 0px;
        span {
            color: @color-secondary;
        }
    }

    &-description {
        margin: 0;
        font-size: 1.3rem;
        display: inline-block;
        width: 53%;
        text-align: left;
        line-height: 1.5rem;
        vertical-align: middle;
    }

    &-button { 
        display: inline-block; 
        right: 0; 
        font-size: 1.3rem;
        text-align:right;
        line-height: 24px;
        vertical-align: middle;
    }

    &--cesar { top: 68px; left: 0px; }
    &--icare { top: 220px; left: 0; right: auto; }
    &--cpa { top: auto; bottom: 54px; left: 0; }
    &--sophaeros { top: 68px; right: 0; left: auto; }
    &--medicis { top: 220px; bottom: auto; left: auto; right: 0; }
    &--sysint { top: auto;  bottom: 54px; left: auto; right: 0; } 
}

@media (min-width: 1300px) {
    .page {
        .astec-modules {
            &-module {
                width: 300px;
            }

            &-submodule {
                padding: 5px 0;
            }
        }
    }
    .astec-module {

        &-header {
            font-size: 2.5rem;
        }

        &-description {
            line-height: 2rem;
            font-size: 1.6rem;
            width: 55%;
        }

        &-button {
            font-size: 1.6rem;
        }

        &--cesar { top: 20px; left: 110px; }
        &--icare { top: 159px; left: 110px; }
        &--cpa { top: auto; bottom: 48px; left: 110px; }
        &--sophaeros { right: 115px; top: 159px; left: auto; }
        &--medicis { top: 20px; left: auto; right: 115px; }
        &--sysint { top: auto; bottom: 48px; left: auto; right: 115px; }
    }
}

@media (min-width: 1920px) {
    .page {
        .astec-modules {
            height: 542px;
            &-module {
                width: 350px;
            }
        }
    }
    .astec-module {

        &-header {
            font-size: 2.5rem;
        }

        &-description {
            font-size: 1.6rem;
            width: 61%;
        }

        &-button {
            font-size: 1.6rem;
        }

        &--cesar { top: 32px; left: 140px; }
        &--icare { top: 179px; left: 140px; }
        &--cpa { top: auto;  bottom: 35px; left: 140px; }
        &--sophaeros { top: 179px; right: 145px; }
        &--medicis { top: 32px; left: auto; right: 145px; }
        &--sysint { top: auto; bottom: 35px; left: auto; right: 145px; }
    }
}

//
// FOOTER
//

footer {
    width: 100%;
    margin: 0 auto;
    background: @color-tertiary;
    padding: 20px 0;
}

footer .container {
    width: 992px;
    margin: 0 auto;
}

@media (min-width: 1300px) {
    footer .container {
        width: 1280px;
    }
}

@media (min-width: 1920px) {
    footer .container {
        width: 1440px;
    }
}